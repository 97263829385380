@import '~@sbb-esta/lyne-elements/standard-theme.css';
body {
    font-family: 'Lato', Arial, sans-serif;
    line-height: 1.75;
    color: #333;
    background-color: #f9f9f9;
    margin: 0;
    padding: 0;
}

/* Container */
.container {
    max-width: 1000px;
    margin: 50px auto;
    padding: 25px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Headings */
h1, h2, h3 {
    color: #222;
    margin-bottom: 15px;
    line-height: 1.3;
}

h1 {
    font-size: 2.5em;
    margin-bottom: 25px;
    border-bottom: 4px solid #0073e6;
    padding-bottom: 10px;
}

h2 {
    font-size: 2em;
}

h3 {
    font-size: 1.5em;
}

/* Welcome Message, Interactive Intro, and Other Sections */
.welcome-message, .interactive-intro, .chat-instructions, .help-button, .cost-projection-container {
    padding: 20px;
    margin: 20px 0;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f1f1f1;
}

/* Travel Card Container */
.travel-cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.travel-card {
    flex: 1 1 300px;
    max-width: 100%;
    border: 1px solid #bbb;
    border-radius: 8px;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.travel-card-title {
    font-size: 1.75em;
    margin-bottom: 10px;
    color: #222;
}

.travel-card-benefit, .travel-card-best-for, .travel-card-key-consideration {
    margin-bottom: 15px;
    font-size: 1em;
}

/* General Link Styles */
a {
    color: #007bff;
    text-decoration: underline;
    font-weight: 600;
    transition: color 0.3s ease, background-color 0.3s ease;
}

a:hover, a:focus {
    color: #0056b3;
    background-color: #e1e1e1;
    outline: 3px solid #ffbf00;
    outline-offset: 3px;
}

a:visited {
    color: #5a5a5a;
}

a::before {
    content: "↗ ";
    font-size: 0.8em;
    color: inherit;
}

/* Buttons */
button {
    background-color: #007bff;
    color: white;
    padding: 12px 24px;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

button:hover, button:focus {
    background-color: #0056b3;
    outline: 3px solid #ffbf00;
    outline-offset: 3px;
}

button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Checkbox and Interactive Forms */
.cost-projection-container {
    padding: 25px;
    background-color: #ffffff;
}

.cost-projection-container label input[type="checkbox"] {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.cost-projection-container label {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding: 10px;
}

.cost-projection-container label input[type="checkbox"]:focus,
.cost-projection-container label input[type="checkbox"]:hover {
    outline: 2px solid #0056b3;
    outline-offset: 2px;
}

/* Footer */
footer {
    text-align: center;
    margin-top: 50px;
    font-size: 0.9em;
    color: #666;
}

/* Media Queries for Mobile */
@media (max-width: 768px) {
    .container {
        padding: 15px;
        margin: 20px auto;
    }

    .travel-card {
        flex: 1 1 100%;
    }

    h1 {
        font-size: 2em;
    }

    h2 {
        font-size: 1.75em;
    }

    h3 {
        font-size: 1.5em;
    }

    button {
        padding: 10px 20px;
    }
}
.travel-habit-row {
    display: flex;
    align-items: center; /* Aligns items vertically in the center */
    margin-bottom: 1rem; /* Adds space between rows */
}

/* Icon between the inputs */
.travel-habit-row .travel-habit-icon {
    display: inline-block; /* Ensures the icon is treated like an inline element */
    align-self: center; /* Ensures the icon stays centered between inputs */
}

/* Media query for smartphones (max-width: 768px) */
@media screen and (max-width: 768px) {
    .travel-habit-row {
        flex-direction: column; /* Stack the inputs vertically */
        align-items: stretch; /* Make inputs take full width */
    }

    .travel-habit-row .travel-habit-icon {
        margin: 0.5rem 0; /* Adjust margin to keep space between inputs */
        align-self: center; /* Keep the icon centered between stacked inputs */
    }
}
