.travel-habits-form {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.travel-habit-fieldset {
    border: none;
    padding: 10px;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 4px;
}

legend {
    font-size: 1.2em;
    margin-bottom: 10px;
}

.form-group {
    margin-bottom: 15px;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
}

input, select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1em;
}

input:focus, select:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.btn {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    font-size: 1em;
    cursor: pointer;
}

.btn-primary {
    background-color: #007bff;
    color: #fff;
}

.btn-secondary {
    background-color: #6c757d;
    color: #fff;
}

.btn:hover {
    opacity: 0.9;
}

.btn:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

@media (max-width: 600px) {
    .travel-habits-form {
        padding: 10px;
    }

    .btn {
        width: 100%;
        margin-bottom: 10px;
    }
}
