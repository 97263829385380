/* Footer Container */
.site-footer {
    background-color: #f8f9fa; /* Light background color */
    padding: 20px 0;
    text-align: center;
    border-top: 1px solid #e7e7e7; /* Light border for separation */
}

.site-footer .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

/* Update text color for better contrast */
.site-footer p {
    margin: 0;
    color: #333; /* Darker color for better readability */
}

/* Legal Links List */
.legal-links {
    list-style: none;
    padding: 0;
    margin: 10px 0 0;
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap to the next line if needed */
    justify-content: center; /* Center the links horizontally */
}

/* Ensure each link is spaced properly and touch targets are large */
.legal-links li {
    margin-right: 15px;
    margin-bottom: 10px; /* Add spacing below each item */
}

/* Update link styles for better contrast and accessibility */
.legal-links li a {
    color: #0056b3; /* Darker blue color for links */
    text-decoration: none;
    padding: 10px 20px; /* Increase padding for a larger touch target */
    display: inline-block; /* Ensure padding is applied correctly */
    text-align: center; /* Center text within the padded area */
    border-radius: 4px; /* Optional: Add rounded corners for aesthetics */
}

.legal-links li a:hover {
    text-decoration: underline;
    background-color: #e7f0ff; /* Optional: Add background color for hover */
}
